import React, { useContext, useEffect } from 'react';
import SEO from '../components/seo';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import { ToastContainer } from 'react-toastify';
import ServicesData from '../utils/servicesData';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../context/GlobalContextProvider';

const Services = () => {
    const { t, i18n } = useTranslation();
    const { language } = useContext(GlobalStateContext);
    useEffect(() => {
    i18n.changeLanguage(language)
    }, [language]);
    
    return (
        <div id="page-wrapper">
            <SEO title="Inicio" />
            <Navigation />
            <ToastContainer />

            <section id="wrapper">
                <header>
                    <div className="inner text-light">
                        <h2>{t('menu.services')}</h2>
                        <p>{t('services.description')}</p>
                    </div>
                </header>

                <ServicesData />

            </section>
            <Footer />
        </div>
    );
};

export default Services;