import React, { FunctionComponent } from 'react';

interface ServiceProps {
    imagePath: string,
    serviceName: string,
    serviceDescription: string
}

const Service: FunctionComponent<ServiceProps> = ({ imagePath, serviceName, serviceDescription }) => {
    return (
        <article>
            <div className="image"><img src={imagePath} alt={serviceName}/></div>
            <h3 className="major font-weight-bold"><img src={require('./../assets/images/isologo.png')} alt="SI" style={{width: 50}} /> {serviceName}</h3>
            <p className="text-light">{serviceDescription}</p>
        </article>
    );
};

export default Service;