
import React, { FunctionComponent, useContext, useEffect } from 'react';
import Service from '../components/service';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../context/GlobalContextProvider';


interface IServicesDataProps {
    steps?: number,
    seeAllButton?: boolean,
    showDescription?: boolean
}

const ServicesData: FunctionComponent<IServicesDataProps> = ({ steps, seeAllButton, showDescription }) => {
    const { t, i18n } = useTranslation();
    const { language } = useContext(GlobalStateContext);
    useEffect(() => {
        i18n.changeLanguage(language)
    }, [language]);
    
    const servicesData = [
        {
            imagePath: require("./../assets/images/img04.jpg"),
            serviceName: t('services.legal.title'),
            serviceDescription: t('services.legal.description')
        },
        {
            imagePath: require("./../assets/images/img05.jpg"),
            serviceName: t('services.contable.title'),
            serviceDescription: t('services.contable.description')
        },
        {
            imagePath: require("./../assets/images/img06.jpg"),
            serviceName: t('services.fiscal.title'),
            serviceDescription: t('services.fiscal.description')
        },
        {
            imagePath: require("./../assets/images/img07.jpg"),
            serviceName: t('services.defensa.title'),
            serviceDescription: t('services.defensa.description')
        },
        {
            imagePath: require("./../assets/images/img08.jpg"),
            serviceName: t('services.administrativa.title'),
            serviceDescription: t('services.administrativa.description')
        },
        {
            imagePath: require("./../assets/images/img09.jpg"),
            serviceName: t('services.corporativa.title'),
            serviceDescription: t('services.corporativa.description')
        },
        {
            imagePath: require("./../assets/images/img10.jpg"),
            serviceName: t('services.laboral.title'),
            serviceDescription: t('services.laboral.description')
        },
        {
            imagePath: require("./../assets/images/img11.jpg"),
            serviceName: t('services.seguridad.title'),
            serviceDescription: t('services.seguridad.description')
        },
        {
            imagePath: require("./../assets/images/img12.jpg"),
            serviceName: t('services.rrhh.title'),
            serviceDescription: t('services.rrhh.description')
        },
        {
            imagePath: require("./../assets/images/img13.jpg"),
            serviceName: t('services.otros.title'),
            serviceDescription: t('services.otros.description')
        }
    ]
    
    const data = steps ? servicesData.slice(0, steps) : servicesData;
    return (
        <section className="wrapper alt style1">
            <div className="inner">
                {showDescription ? 
                <React.Fragment>
                    <h2 className="major">{t('menu.services')}</h2>
                    <p className="text-light">{t('services.description')}</p>
                </React.Fragment>
                :null}
                <section className="features">
                    {data.map((service, index) => (
                    <Service
                    key={index}
                    imagePath={service.imagePath}
                    serviceName={service.serviceName}
                    serviceDescription={service.serviceDescription}
                    />
                    ))}
                    {seeAllButton ? 
                    <ul className="actions">
                        <li><button onClick={() => navigate("/services")}>{t('button.seemore')}</button></li>
                    </ul>
                    : null}
                </section>
            </div>
        </section>
    );
};

export default ServicesData;